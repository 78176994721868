document.addEventListener('DOMContentLoaded', function() {
  const header = document.querySelector('header');
  const subMenus = header.querySelectorAll('.sub-menu');

  subMenus.forEach(function(subMenu) {
    const postItem = subMenu.querySelector('.post-item');

    if (postItem) {
      subMenu.style.setProperty('--tw-translate-x', '-300px');
      subMenu.appendChild(postItem);
    }
  });
});